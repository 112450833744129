
var sw = window.innerWidth;
var sh = window.innerHeight;
var header = document.querySelector("header");
var sticky = 0;

// Font Resizer ------------------- Start
  function fontResizer() {
    var perc = parseInt(sw) / 120;
    document.body.style.fontSize = perc + 'px';
  }


// Landscape Mode off ---------------- Start
    const mql = window.matchMedia("(orientation:landscape)");
    mql.addEventListener("change", (event) => {
      if (event.matches) {
        if(innerWidth < 930 ) {
          // console.log("Now in landscape orientation");
          document.getElementById("portrait-warning").style.display = "flex";
        }
      } else {
        // console.log("Now in portrait orientation");
        document.getElementById("portrait-warning").style.display = "none";
      }
    });

    // function landscapeModeOff() {
    //   if (sh < 450 && sw > 480 && sw < 820) {
    //     if(document.getElementById("portrait-warning")) {
    //       document.getElementById("portrait-warning").style.display = "flex";
    //     }
    //   } else {
    //     if(document.getElementById("portrait-warning")) {
    //       document.getElementById("portrait-warning").style.display = "none";
    //     }
    //   }
    // }

// Loader ------------------- Start
    function pageLoader() {
      // document.querySelector("html").classList.add("loadjs");
      setTimeout(function () {
        if(document.querySelector(".loader-first")) {
          document.querySelector(".loader-first").style.display = "none";
          document.querySelector("html").classList.remove("loadjs");
        }
      }, 700);
    }

// Sticky Header ---------------- Start
(function () {
  if (document.querySelector('.header')) {
    // Sticky Header Class Add and Remove ---------------- Start
    function stickyHeader() {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    }
    // Sticky Header ---------------- End

    // Window On Scroll-----------------Start
    window.addEventListener('scroll', (event) => {
      stickyHeader();
    });
  }
})();

// Window on Load ----------------- Start
  window.onload = function () {
    pageLoader();
    // landscapeModeOff();
    // screenSize()
    if (sw > 1199) {
      fontResizer();
    }
  };

// Window On Resize ----------------- Start
(function () {
  window.addEventListener('resize', (event) => {
    sw = window.innerWidth;
    sh = window.innerHeight;
    // landscapeModeOff();
    if (sw > 1025) {
      if (sw < 1400 && sw > 1300 && sh > 900) {} else {
        fontResizer();
      }
    }
  });
})();

// SVG Convert Code
window.addEventListener("load", function() {
  (function() {
    if(document.querySelector(".svg-convert")) {
      let svgs = document.querySelectorAll('img.svg-convert');
      svgs.forEach((el) => {
        const imgID = el.getAttribute('id');
        const imgClass = el.getAttribute('class');
        const imgURL = el.getAttribute('src');
    
        fetch(imgURL)
          .then((response) => response.text())
          .then(data => {
              const parser = new DOMParser();
              const xmlDoc = parser.parseFromString(data, 'text/html');
              let svg = xmlDoc.querySelector('svg');
              if (typeof imgID !== 'undefined') {
                  svg.setAttribute('id', imgID);
              }
    
              if (typeof imgClass !== 'undefined') {
                  svg.setAttribute('class', imgClass + ' svg-converted');
              }
    
              svg.removeAttribute('xmlns:a');
    
              el.parentNode.replaceChild(svg, el);
          })
      });
    }
  }) ();

});


// Navigation slide Active on a mobile ---------------- Start
(function () {
  if (document.querySelector('#toggle')) {
    let toggleMenu = document.querySelector('#toggle');
    let htmlEl = document.querySelector("html");

    toggleMenu.addEventListener("click", (event) => {
      console.log('this', event)
      let _this = event.target;
      _this.classList.toggle('active');
      document.querySelector('.navigation').classList.toggle('open')
      htmlEl.classList.toggle("overflow-hidden");
    })
  }
})();

// navigation accordion ---------------- Start
(function() {
  if (document.querySelector('.navigation')) {
    if(sw < 1200) {
      let dropdown = document.querySelectorAll(".dropdown");
      dropdown.forEach(function(dp) {
        let dropdownItem = dp.closest(".menu__menuitem");
        dropdownItem.classList.add("dropdown-active");
      });
    
      let accordionNav = document.querySelectorAll(".menu__menuitem.dropdown-active");
      let iterator;

      for (iterator = 0; iterator < accordionNav.length; iterator++) {
        accordionNav[iterator].querySelector(".menu__menuitem__icon").addEventListener("click", function (e) {
          e.stopPropagation();
          let currentItem = e.target;

          // console.log(currentItem)
          let panel = this.nextElementSibling;
          if(currentItem.classList.contains('open')) {
            currentItem.classList.remove("open");
            panel.style.maxHeight = '0px';
          } else {
            let mainItem = currentItem.closest(".menu").querySelectorAll(".menu__menuitem__icon");
              mainItem.forEach(function(itm) {
              itm.classList.remove("open");
              itm.nextElementSibling.style.maxHeight = '0px';
            });
            currentItem.classList.add("open");
            panel.style.maxHeight = panel.scrollHeight + 'px';
          }
        });
      }
    }
  }
})();

// footer accordion
(function() {
  if(sw < 576) {
    let accordion = document.getElementsByClassName("footer__links");
    let j;
    for (j = 0; j < accordion.length; j++) {
      accordion[j].querySelector(".h6").addEventListener("click", function (e) {
        let currentItem = e.target;
        let panel = this.nextElementSibling;
        if(currentItem.classList.contains('opened')) {
          currentItem.classList.remove("opened");
          panel.style.maxHeight = '0px';
        } else {
          let mainItemLinks = currentItem.closest(".footer__links-wrapper");
          console.log(mainItemLinks)
            mainItemLinks.querySelectorAll(".footer__links").forEach(function(ml) {
              let mainItem =  ml.querySelectorAll(".h6")
              mainItem.forEach(function(itm) {
              itm.classList.remove("opened");
              itm.nextElementSibling.style.maxHeight = '0px';
            })
          });
          currentItem.classList.add("opened");
          panel.style.maxHeight = panel.scrollHeight + 'px'
        }
      });
    }
  }
})();



// custom select to
(function () {
  var x, i, j, l, ll, selElmnt, a, b, c;
  /*look for any elements with the class "custom-select":*/
  x = document.getElementsByClassName("custom-select");
  l = x.length;
  for (i = 0; i < l; i++) {
    selElmnt = x[i].getElementsByTagName("select")[0];
    ll = selElmnt.length;
    /*for each element, create a new DIV that will act as the selected item:*/
    a = document.createElement("DIV");
    a.setAttribute("class", "select-selected");
    a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
    x[i].appendChild(a);
    /*for each element, create a new DIV that will contain the option list:*/
    b = document.createElement("DIV");
    b.setAttribute("class", "select-items select-hide");
    for (j = 1; j < ll; j++) {
      /*for each option in the original select element,
    create a new DIV that will act as an option item:*/
      c = document.createElement("DIV");
      c.innerHTML = selElmnt.options[j].innerHTML;
      c.addEventListener("click", function (e) {
        /*when an item is clicked, update the original select box,
        and the selected item:*/
        var y, i, k, s, h, sl, yl;
        s = this.parentNode.parentNode.getElementsByTagName("select")[0];
        sl = s.length;
        h = this.parentNode.previousSibling;
        for (i = 0; i < sl; i++) {
          if (s.options[i].innerHTML == this.innerHTML) {
            s.selectedIndex = i;
            h.innerHTML = this.innerHTML;
            y = this.parentNode.getElementsByClassName("same-as-selected");
            yl = y.length;
            for (k = 0; k < yl; k++) {
              y[k].removeAttribute("class");
            }
            this.setAttribute("class", "same-as-selected");
            break;
          }
        }
        h.click();
      });
      b.appendChild(c);
    }
    x[i].appendChild(b);
    a.addEventListener("click", function (e) {
      /*when the select box is clicked, close any other select boxes,
      and open/close the current select box:*/
      let heroformDropdown = document.querySelectorAll(".hero-form__col:not(.hero-form__col--btnn) .hero-form__dropdown");
      heroformDropdown.forEach(function (elmt) {
        elmt.classList.remove("active");
      });

      e.stopPropagation();
      closeAllSelect(this);
      this.nextSibling.classList.toggle("select-hide");
      this.classList.toggle("select-arrow-active");
    });
  }
  function closeAllSelect(elmnt) {
    /*a function that will close all select boxes in the document,
  except the current select box:*/
    var x,
      y,
      i,
      xl,
      yl,
      arrNo = [];
    x = document.getElementsByClassName("select-items");
    y = document.getElementsByClassName("select-selected");
    xl = x.length;
    yl = y.length;
    for (i = 0; i < yl; i++) {
      if (elmnt == y[i]) {
        arrNo.push(i);
      } else {
        y[i].classList.remove("select-arrow-active");
      }
    }
    for (i = 0; i < xl; i++) {
      if (arrNo.indexOf(i)) {
        x[i].classList.add("select-hide");
      }
    }
  }
  /*if the user clicks anywhere outside the select box,
then close all select boxes:*/
  document.addEventListener("click", closeAllSelect);
})();




// page active link
(function () {
  window.addEventListener('load', function (e) {
    let myPage = location.pathname,
        link = document.querySelector(`.menu__menuitem--menulink[href="${myPage}"]`),
        innerDropdownLink = document.querySelector(`.dropdown__item a[href="${myPage}"]`);
        // console.log(link)

    if (link) {
      link.classList.add('active');
    }
    if (innerDropdownLink) {
      let linkParent = innerDropdownLink.closest('.menu__menuitem')
      let currentLink = linkParent.querySelector('.menu__menuitem--menulink')
      currentLink.classList.add('active');
      //  Current Link
      let currentLinkDropdown = innerDropdownLink.closest('.dropdown__item > a');
      currentLinkDropdown.classList.add('active');
    }
  })
})();


// -------------------- above code will be common for all websites --------------------------

// home our mission accordion

// (function() {
//   let vision__accordion_wrapper = document.querySelector(".h-our-vision__accordion-wrapper .h-our-vision__accordion:first-child");
//   let firstVisionAcc = vision__accordion_wrapper.querySelector(".h-our-vision__accordion__content");
//   firstVisionAcc.style.maxHeight = firstVisionAcc.scrollHeight + 'px';

//     let accordion = document.getElementsByClassName("h-our-vision__accordion");
//     let j;
//     for (j = 0; j < accordion.length; j++) {
//       accordion[j].querySelector(".h-our-vision__accordion__title").addEventListener("click", function (e) {
//         let currentItem = e.target;
//         let panel = this.nextElementSibling;
//         let currentParentAccord = currentItem.closest(".h-our-vision__accordion");
  
//         if(currentItem.classList.contains('opened')) {
//           currentItem.classList.remove("opened");
//           currentParentAccord.classList.remove("open-accordion")
//           panel.style.maxHeight = '0px';
//         } else {
//           let mainItem = currentItem.closest(".h-our-vision__accordion-wrapper").querySelectorAll(".h-our-vision__accordion__title");
//             mainItem.forEach(function(itm) {
//             itm.classList.remove("opened");
//             itm.nextElementSibling.style.maxHeight = '0px';
//           });
  
//           let accAll = currentItem.closest(".h-our-vision__accordion-wrapper").querySelectorAll(".h-our-vision__accordion");
//           accAll.forEach(function(ac) {
//             ac.classList.remove("open-accordion");
//           });

//           currentItem.classList.add("opened");
//           panel.style.maxHeight = panel.scrollHeight + 'px';
//           currentParentAccord.classList.add("open-accordion")
//         }
  
  
//       });
//     }
  
//   })();



// example for sample
// if (sw < 767) {
//     if (document.querySelector('.our-partners__partner-slider')) {
//         var partnerSlider = new Swiper(".our-partners__partner-slider", {
//             slidesPerView: 2,
//             loop: true,
//             speed: 2000,
//             autoplay: {
//                 enable: true,
//                 delay: 2000,
//                 disableOnInteraction: false,
//             },
//         });
//     }
// }


// companies logoes
(function() {
  if (document.querySelector('.comp-logoes')) {
      var companieslogoes = new Swiper(".comp-logoes .swiper", {
          slidesPerView: 2,
          loop: true,
          speed: 2000,
          spaceBetween: 20,
          allowTouchMove: true,
          observeParents: true,
          observer: true,
          autoplay: {
              delay: 2500,
              disableOnInteraction: false,
          },
          breakpoints: {
              768: {
                slidesPerView: 5,
                spaceBetween: 58,
              }
          },
      });
  }
})();




// how we find
(function() {
  if (document.querySelector('.how-find__slider')) {
      var howwefind = new Swiper(".how-find__slider .swiper", {
          slidesPerView: 1,
          loop: true,
          speed: 2000,
          spaceBetween: 10,
          allowTouchMove: true,
          observeParents: true,
          observer: true,
          autoplay: {
              delay: 2500,
              disableOnInteraction: false,
          },
          pagination: {
            el: ".custom-sliderfractions",
            type: "custom",
            renderCustom: function (swiper, current, total) {
              return (
                `<div class="custom-sliderfractions__inner"> <span>0${current} / </span> <span>0 ${total}</span>  </div>` 
              )   
            },
          },
          breakpoints: {
              576: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              992: {
                slidesPerView: "auto",
                spaceBetween: 0,
              },


          },
      });
  }
} ) ();



// our services
(function() {
  if (document.querySelector('.our-services__slider-wrapper')) {
      var ourservices = new Swiper(".our-services__slider-wrapper .swiper", {
          slidesPerView: 1,
          centeredSlides: true,
          loop: true,
          speed: 2000,
          spaceBetween: 0,
          allowTouchMove: true,
          observeParents: true,
          observer: true,
          watchSlidesProgress: true,
          pagination: {
            el: ".custom-sliderfractions2",
            type: "custom",
            renderCustom: function (swiper, current, total) {
              return (
                `<div class="custom-sliderfractions__inner"> <span>${current} / </span> <span> ${total}</span>  </div>` 
              )   
            },
          },
          navigation: {
            nextEl: '.our-services__slider-wrapper .swiper-button-next',
            prevEl: '.our-services__slider-wrapper .swiper-button-prev',
          },
          // autoplay: {
          //     delay: 2500,
          //     disableOnInteraction: false,
          // },
          breakpoints: {
              576: {
                slidesPerView: 1.9,
                spaceBetween: 0,
              }
          },
      });
  }
} ) ();



// project slider
(function() {
  if (document.querySelector('.projects-sec__slider')) {
      var projectslider = new Swiper(".projects-sec__slider .swiper", {
          slidesPerView: 1,
          // centeredSlides: true,
          loop: true,
          speed: 2000,
          spaceBetween: 0,
          allowTouchMove: true,
          observeParents: true,
          observer: true,
          watchSlidesProgress: true,
          // autoplay: {
          //     delay: 2500,
          //     disableOnInteraction: false,
          // },
          breakpoints: {
              576: {
                slidesPerView: 1.5,
                spaceBetween: 0,
              },
              992: {
                slidesPerView: 2.5,
              },
              1200: {
                slidesPerView: 3.5,
              },
          },
      });
  }
} ) ();

// investment slider
(function() {
  if (document.querySelector('.investment__slider')) {
      var investmentslider = new Swiper(".investment__slider .swiper", {
          slidesPerView: 1,
          centeredSlides: true,
          loop: true,
          speed: 2000,
          spaceBetween: 0,
          allowTouchMove: true,
          observeParents: true,
          observer: true,
          watchSlidesProgress: true,
          autoplay: {
              delay: 2500,
              disableOnInteraction: false,
          },
          breakpoints: {
              576: {
                slidesPerView: 2.5,
              },
              1200: {
                slidesPerView: 4.5,
                spaceBetween: 0,
              },

          },
      });
  }
} ) ();



// inspiration slider
(function() {
  if (document.querySelector('.inspiration__slider')) {
      var inspirationslider = new Swiper(".inspiration__slider .swiper", {
          slidesPerView: 1,
          loop: true,
          speed: 2000,
          spaceBetween: 0,
          allowTouchMove: true,
          observeParents: true,
          observer: true,
          watchSlidesProgress: true,
          autoplay: {
              delay: 2500,
              disableOnInteraction: false,
          },
          breakpoints: {
              576: {
                slidesPerView: "auto",
                spaceBetween: 0,
              }
          },
      });
  }
} ) ();


// blog slider
(function() {
  if (document.querySelector('.blog-slider')) {
      var blogslider = new Swiper(".blog-slider .swiper", {
          slidesPerView: 1,
          loop: true,
          speed: 2000,
          spaceBetween: 30,
          allowTouchMove: true,
          observeParents: true,
          observer: true,
          watchSlidesProgress: true,
          autoplay: {
              delay: 2500,
              disableOnInteraction: false,
          },
          breakpoints: {
              576: {
                slidesPerView: 2,
              },
              992: {
                slidesPerView: 3,
              },

          },
      });
  }
} ) ();






// accordion for faqs page
(function() {
  if (document.querySelector('.faqs-sec')) {    
      let frstAccord = document.querySelector(".faqs-sec__accordionn-wrapper");
      let frstAccordContent = frstAccord.querySelector(".faqs-sec__accordionn-wrapper__content"); 
      frstAccordContent.style.maxHeight = frstAccordContent.scrollHeight + 'px';

      let accordion = document.getElementsByClassName("faqs-sec__accordionn-wrapper");
      let j;
      for (j = 0; j < accordion.length; j++) {
        accordion[j].querySelector(".h6").addEventListener("click", function (e) {
          let currentItem = e.target;
          let panel = this.nextElementSibling;
          if(currentItem.classList.contains('opened')) {
            currentItem.classList.remove("opened");
            panel.style.maxHeight = '0px';
          } else {
            let mainItem = currentItem.closest(".faqs-sec").querySelectorAll(".h6");
              mainItem.forEach(function(itm) {
              itm.classList.remove("opened");
              itm.nextElementSibling.style.maxHeight = '0px';
            });
            currentItem.classList.add("opened");
            panel.style.maxHeight = panel.scrollHeight + 'px'
          }
        });
      }
  }
})();




// home follow us
(function() {
  if (document.querySelector('.h-follow-us__slider')) {
      var homeFollow = new Swiper(".h-follow-us__slider .swiper", {
          slidesPerView: 1,
          loop: false,
          speed: 2000,
          spaceBetween: 16,
          allowTouchMove: true,
          observeParents: true,
          observer: true,
          // autoplay: {
          //     delay: 2500,
          //     disableOnInteraction: false,
          // },
          breakpoints: {
              576: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 3,
              },
              992: {
                slidesPerView: 4,
              },
              1200: {
                slidesPerView: 6,
              },

          },
      });
  }
})();
 


// form input animation 
(function() {

  if(document.querySelector(".home-hero__form")) {
    let formFieldcontrol = document.querySelectorAll(".c_form-field .c_form-field__control");
  
    formFieldcontrol.forEach(function(ff) {
      ff.addEventListener("focus", function(evt) {
        console.log(evt.target.closest(".c_form-field").classList.add("focused") )
      });
  
      ff.addEventListener("blur", function(e) {
        var inputValue = e.target.value;
        console.log(inputValue)
        if(inputValue == "") {
          e.target.classList.remove('filled');
          e.target.closest(".c_form-field").classList.remove("focused") 
        }
        else {
          e.target.classList.add("filled")
        }
      })
  
  
    });
  }
}) ();


// counter
(function () {
  if (document.querySelector('.welcome-sec__right-side')) {
    // Get the element you want to observe
    const targetElement = document.querySelector('.welcome-sec__right-side');

    // Create a new intersection observer
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // Start the counter animation when the element is in view
          startCounterAnimation();
          
          // Stop observing once the element is in view
          observer.unobserve(entry.target);
        }
      });
    });

    // Start observing the target element
    observer.observe(targetElement);

    // Function to start the counter animation
    function startCounterAnimation() {
      // Get all the counter elements
      const counterElements = document.querySelectorAll('.counter');
      
      // Loop through each counter element
      counterElements.forEach(counterElement => {
        // Get the target value for the counter
        const targetValue = parseInt(counterElement.textContent);
        
        // Set the initial value to 0
        counterElement.textContent = 0;
        
        // Start the counter animation
        animateCounter(counterElement, targetValue);
      });
    }

    // Function to animate the counter
    function animateCounter(counterElement, targetValue) {
      let currentValue = 0;
      const duration = 15000; // Duration of the animation in milliseconds
      const increment = Math.ceil(targetValue / (duration / 60 )); // Change this value to adjust the animation speed
      
      // Function to update the counter value
      function updateCounter() {
        currentValue += increment;
        
        // Check if the counter value has reached the target value
        if (currentValue >= targetValue) {
          counterElement.textContent = targetValue;
        } else {
          counterElement.textContent = currentValue;
          requestAnimationFrame(updateCounter);
        }
      }
      
      // Start the counter animation
      requestAnimationFrame(updateCounter);
    }
  }
})();



// tabs for filter
(function() {

  // Check if the "projects-sec" class is present on the page
  if (document.querySelector(".projects-sec")) {
    function filterTabs(filterTabs, tabsBtns ) {
      filterTabs.addEventListener("click", function(e) {
        if(e.target.matches(".active")) {
          if(sw < 576 ) {
            tabsBtns.forEach(function(tabbtn) {
              if(window.getComputedStyle(tabbtn).display === "none") {
                tabbtn.style.display = "block";
              }
              else {
                tabbtn.style.display = "none";
                e.target.style.display = "block";
              }
            });
          }
        }
        else {
          tabsBtns.forEach(function(tabbtn) {
            tabbtn.classList.remove("active");
          })
          e.target.classList.add("active");
        }
      })
    }
    
    // Call filterTabs function for projects tab
    filterTabs(document.querySelector(".c-tab--projects"), document.querySelectorAll(".c-tab--projects .rectangle-btn"));
    
    // Call filterTabs function for interested tab
    filterTabs(document.querySelector(".c-tab--interested"), document.querySelectorAll(".c-tab--interested .rectangle-btn"));
  }

})();



//  International Telephone Input
// Phone Country Code Dropdown - Start
(function () {
  if (document.querySelectorAll(".phoneiti").length > 0) {
    document.addEventListener("DOMContentLoaded", function () {
      const inputs = document.querySelectorAll(".phoneiti");
      inputs.forEach(function (intll) {
        const iti = window.intlTelInput(intll, {
          hiddenInput: "full_phone",
          initialCountry: "ae", // Adjust as needed
          /* For Country Code */
          separateDialCode: true,
          geoIpLookup: function (callback) {
            fetch("https://ipinfo.io/json")
              .then((response) => response.json())
              .then((data) => {
                const countryCode = data?.country || "us";
                //console.log(data);
                callback(countryCode);
              })
              .catch(() => callback("us")); // Fallback to "us" in case of error
          },
          utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.19/build/js/utils.js",
        });

        // Update the hidden field on input or country change
        intll.addEventListener("input", function () {
          updateHiddenField(iti, intll);
        });
        intll.addEventListener("countrychange", function () {
          updateHiddenField(iti, intll);
        });
      });

      // Function to update the hidden field with the country code + phone number
      function updateHiddenField(iti, intll) {
        const fullPhone = iti.getNumber();
        const hiddenInput = intll.closest("form").querySelector('[name="full_phone"]');
        if (hiddenInput) {
          hiddenInput.value = fullPhone;
        }
      }
    });
  }
})();
 // Phone Country Code Dropdown - End


// Show HTML element
Fancybox.bind("[data-fancybox]", {
  src: "#dialog-content",
  type: "inline"
});